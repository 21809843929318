<template>

    <b-modal id="modal-config" size="lg">
        <template v-slot:modal-title>{{$t('modal_config.title')}}</template>
        <div class="position-relative">
            <Errors/>
        </div>

        <Loading v-if="loading"/>
        <div v-else>

            <div class="d-flex justify-content-between">
                <div>
                    <b-form-checkbox
                            v-model="rtc.auto_connect"
                            @change="changeAutoConnect"
                    > {{$t('modal_config.auto_connect')}}
                    </b-form-checkbox>
                </div>
                <div>{{$t('modal_config.language')}} :
                    <b-form-select class="w-auto" v-model="$i18n.locale" :options="$i18n.availableLocales"
                                   @change="changeLocale">
                        <!--<option v-for="(lang) in $i18n.availableLocales" :key="lang" :value="lang">{{ lang }}</option>-->
                    </b-form-select>
                </div>
            </div>
            <hr>
            <h5 class="text-center text-info">{{$t('modal_config.select_type_of_connect')}}</h5>
            <div class="d-flex justify-content-center">
                <div>
                    <b-form-radio-group
                            id="btn-radios-1"
                            v-model="rtc.connection_type"
                            :options="connexion_type_values"
                            buttons
                            name="radios-btn-default"
                    />
                </div>
            </div>
            <b-form-group
                    id="input-group-1" v-bind:label="$t('modal_config.audio_input_source')" label-for="input-1">
                <b-form-select class="w-100" v-model="rtc.audio_input" :options="rtc.audio_input_values"
                               @change="changeAudioInput">
                </b-form-select>
            </b-form-group>
            <transition name="fade">
                <div v-if="rtc.connection_type === 'video'">
                    <div v-if="rtc.isMobileDevice">
                        <b-form-group id="input-group-4" v-bind:label="$t('modal_config.video_source')" label-for="input-4">
                            <b-form-select class="w-100" v-model="rtc.facing_mode" :options="list_facing_mode"
                                           @change="changeVideoInput">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div v-else>
                        <b-form-group id="input-group-3" v-bind:label="$t('modal_config.video_source')" label-for="input-3">
                            <b-form-select class="w-100" v-model="rtc.video_input" :options="rtc.video_input_values"
                                           @change="changeVideoInput">
                            </b-form-select>
                        </b-form-group>
                    </div>
                    <div class="d-flex">
                        <b-form-group id="input-group-5" class="w-50 p-1" v-bind:label="$t('modal_config.video_resolution')" label-for="input-5">
                            <b-form-select class="w-100" v-model="rtc.currentResolution"
                                           @change="changeVideoParameter(true)">
                              <b-form-select-option v-for="(res,index) in rtc.list_of_resolution" :key="index" :value="res">{{res.resolution}}</b-form-select-option>
                            </b-form-select>
                        </b-form-group>
                        <b-form-group id="input-group-6" class="w-50 p-1" v-bind:label="$t('modal_config.video_framerate')" label-for="input-6">
                            <b-form-select class="w-100" v-model="rtc.framerate" :options="rtc.list_of_framerate"
                                           @change="changeVideoParameter(false)">
                            </b-form-select>
                        </b-form-group>
                    </div>

                </div>
            </transition>
        </div>
        <template v-slot:modal-footer>
            <div class="w-100 d-flex justify-content-center">
                <b-button v-if="!rtc.in_progress" variant="success" class="d-flex" v-on:click="connect"
                          v-bind:class="{disabled: loading }">
                        <span>
                            {{$t('live.start')}}
                        </span>
                    <b-icon icon="telephone" font-scale="1.5" class="ml-3 align-self-center"/>
                </b-button>
                <b-button v-else variant="warning" class="d-flex" @click="hideModal">
                        <span>
                            {{$t('live.close')}}
                        </span>
                    <b-icon icon="x" font-scale="1.5" class="ml-3 align-self-center"/>
                </b-button>
            </div>
        </template>
    </b-modal>
</template>

<script>
    //import {ConfJson} from "@/main";
    import Loading from "@/components/Loading"
    import Errors from "@/components/Errors"
    import i18n from '@/i18n';

    export default {
        name: "ConfigModal",
        props: {},
        data: function () {
            return {
                connexion_type_values: [
                    {text: 'Audio', value: 'audio', disabled: true},
                    {text: 'Audio & Video', value: 'video'},
                ],
                list_facing_mode: [
                    {text: i18n.t('modal_config.facing_mode_user'), value: 'user'},
                    {text: i18n.t('modal_config.facing_mode_environement'), value: 'environment'},
                ],
            }
        },
        components: {
            Loading,
            Errors,
        },
        created: function () {
            //this.$store.commit('key/getDevice')
        },
        computed: {
            rtc() {
                return this.$store.state.key.rtc_connection
            },
            error() {
                return this.$store.state.key.error
            },
            loading() {
                return this.$store.state.key.loading
            }
        },
        methods: {
            hideModal() {
                this.$nextTick(() => {
                    this.$bvModal.hide('modal-config')
                })
            },
            changeLocale() {
                localStorage.setItem('locale', this.$i18n.locale)
            },
            changeAudioInput() {
                this.$store.commit('key/changeAudioInput')
            },
            changeAudioOutput() {
                this.$store.commit('key/changeAudioOutput', this.rtc.audio_output)

            },
            changeVideoInput() {
                this.$store.commit('key/changeVideoInput')
            },
            changeVideoParameter(isResolution) {
                this.$store.commit('key/changeVideoParameter',isResolution )
            },
            changeVideoInputClick() {
                console.log("click")

            },
            changeAutoConnect() {
                this.$nextTick(() => {
                    localStorage.setItem('auto_connect', this.rtc.auto_connect)
                })
            },
            setVideo: function (value) {
                this.$store.commit('key/setVideo', value)
            },
            connect() {
                this.$store.commit('key/connect')
                this.$bvModal.hide('modal-config')
            },
        },
    }
</script>

<style scoped>
    .border-dash-style {
        flex-wrap: wrap;
        padding: 5px;
        border: 2px dashed rgba(0, 0, 0, 0.5);
    }

    .border-dash-style > button {
        width: 75px;
        word-wrap: break-word;
        min-height: 40px;
        margin: 2px;
    }
</style>