import Vue from 'vue'
import Vuex from 'vuex'
import i18n from './i18n'
import VueMeta from 'vue-meta'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import vueFullscreen from 'vue-fullscreen'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
//import DetectRTC from 'detectrtc';

import $ from 'jquery'
import store from './vuex/store'
import App from './App.vue'
import router from './router'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
//import VueResource from "vue-resource";
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

export var ServiceUrl = null, ConfJson = null, version = "v1.3.0";

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex)

Vue.use(VueMeta)
Vue.use(PerfectScrollbar)
Vue.use(vueFullscreen)
//Vue.use(DetectRTC)


Vue.component('loading',{ template: '<div>Loading!</div>'})

new Vue({
  data: {loading: true},

  methods: {
    getConfJson: function () {

      $.getJSON('setup.json',function (data) {
        ServiceUrl = data.ines_caller_ws.service_url;
        getConf()
      }).done().fail( function (e) {
        if (e.status === 404) {
          $.getJSON('dev.setup.json',function (data) {
            ServiceUrl = data.ines_caller_ws.service_url;
            getConf()
          }).done().fail( function (e) {
            if (e.status === 404) {
              alert("Fichier de configuration Manquant !")
            }
          })
        }
      })
    },
  },

  beforeMount: function () {
    this.getConfJson();
  },

  mounted: function () {

    let url = new URL(window.location.href);
    let s = url.searchParams.get("s");
    let s2 = localStorage.getItem("s");

    if ((s !== undefined && s !== null && s.length > 0) ||
        (s2 !== undefined && s2 !== null && s2.length > 0) && router.currentRoute.path !== '/live' ) {
      if (s !== undefined && s !== null && s.length > 0)
        localStorage.setItem("s", encodeURIComponent(s));
      router.push("/live")
    } else if ((s === undefined || s === null || s.length === 0) &&
        (s2 === undefined || s2 === null || s2.length === 0) && router.currentRoute.path !== '/') {

      router.push("/")
    }
  },
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')

function getConf() {
  $.getJSON(ServiceUrl + "/public?", {
    fnct: "get_conf","brand": localStorage.getItem("BrandName"),
  }).done(function (data) {
    ConfJson = data
  }).fail(function (e){alert(JSON.stringify(e))}).always(function () {
  });
}